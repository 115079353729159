import {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

import * as walletService from '../../services/Issuer/walletService';

export const WalletContext = createContext({});

const WalletProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [withdrawDetails, setWithdrawDetails] = useState();
  const [depositDetails, setDepositDetails] = useState();
  const [showBalance, setShowBalance] = useState('visibility_off');
  const [loadingDepositPix, setLoadingDepositPix] = useState(false);
  const [depositPixString, setDepositPixString] = useState();

  const navigate = useNavigate();

  const getWithdraws = useCallback(async (page, pageSize, filters) => {
    const params = {
      page: page ? page - 1 : 0,
      pageSize: pageSize ? pageSize : 12,
      ...(filters && { ...filters }),
    };
    setLoading(true);
    try {
      const { data } = await walletService.getIssuerWithdraws(params);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getWithdrawDetails = useCallback(async (issuerWithdrawId) => {
    setLoading(true);
    try {
      const { data } = await walletService.getWithdrawDetails(issuerWithdrawId);
      setWithdrawDetails(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const generateWithdrawToken = useCallback(async (body) => {
    try {
      await walletService.generateToken(body);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const validateToken = useCallback(async (params) => {
    try {
      const { data } = await walletService.validateToken(params);
      return data;
    } catch (error) {
      console.error(error);
    }
  });

  const finishWithdrawing = useCallback(async (issuerWithdrawId, params) => {
    setLoadingButton(true);
    try {
      const { data } = await walletService.requestWithdraw(issuerWithdrawId, params);
      return data;
    } catch (error) {
      console.error(error);
      swal("Falha ao solicitar o saque", "Falha ao solicitar o saque via pix, tente novamente", 'error');

    } finally {
      setLoadingButton(false);
    }
  }, []);

  const getDeposits = useCallback(async (page, pageSize, filters, issuerId) => {
    const params = {
      page: page ? page - 1 : 0,
      pageSize: pageSize ? pageSize : 12,
      ...(filters && { ...filters }),
      issuerId,
    };
    setLoading(true);
    try {
      const { data } = await walletService.listDeposits(params);
      return data.data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getDepositDetails = useCallback(
    async (depositId) => {
      setLoading(true);
      try {
        const { data } = await walletService.getDepositDetails(depositId);
        setDepositDetails(data);
        return data;
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [depositDetails],
  );

  const confirmDeposit = useCallback(async (params) => {
    setLoadingButton(true);
    try {
      const data = await walletService.confirmDeposit(params);
      return true;
    } catch (error) {
      return false;
    } finally {
      setLoadingButton(false);
    }
  }, []);

  const getIssuerBalance = useCallback(async (issuerId) => {
    setLoading(true);
    try {
      const { data } = await walletService.getIssuerWallet(issuerId);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getDepositPixString = useCallback(async (value) => {
    setLoadingDepositPix(true);
    const body = {
      value: +value,
      issuerId: localStorage.getItem('issuerId'),

    };
    try {
      const { data } = await walletService.cresteIssuerDepositPix(body);
      setDepositPixString(data)
      return data;
    } catch (error) {
      swal("Falha ao gerar pix", "Falha ao gerar o pix de cobrança, tente novamente", 'error');

      console.error(error);
    } finally {
      setLoadingDepositPix(false);
    }
  }, []);


  const balanceController = useCallback(() => {
    if (showBalance === 'visibility_off') setShowBalance('visibility_on');
    else setShowBalance('visibility_off');
  });

  return (
    <WalletContext.Provider
      value={{
        loading,
        getWithdraws,
        getWithdrawDetails,
        withdrawDetails,
        generateWithdrawToken,
        validateToken,
        finishWithdrawing,
        loadingButton,
        confirmDeposit,
        getDeposits,
        getDepositDetails,
        depositDetails,
        getIssuerBalance,
        balanceController,
        showBalance,
        loadingDepositPix,
        setLoadingDepositPix,
        depositPixString,
        getDepositPixString,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

const useWallet = () => {
  return useContext(WalletContext);
};

export { useWallet, WalletProvider };
