import React, {
  useEffect,
  useState,
} from 'react';

import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  CodeValidator,
  Countdown,
  Translator,
} from '@components';
import {
  useBank,
  useWallet,
} from '@contexts-issuer';

const RequestWithdraw = ({ props: { show, setShow, setSucess, isReview = false, isDone = false } }) => {
  const { withdrawDetails, generateWithdrawToken, validateToken, finishWithdrawing, loadingButton } = useWallet();
  const { getAllBanks } = useBank();

  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [code, setCode] = useState();
  const [bankInfo, setBankInfo] = useState();
  const successObj = { msg: '' };
  const [retry, setRetry] = useState(successObj);

  const onHideModal = () => {
    setShow(false);
  };

  const sendToken = () => {
    const body = {
      issuerWithdrawId: withdrawDetails.issuerWithdrawId,
    };
    generateWithdrawToken(body);
  };

  const resend = () => {
    if (step === 1 && withdrawDetails) sendToken();
    const success = { ...successObj };
    success.msg = t('pages.withdrawList.resend').toString();
    setRetry(success);
  };

  const finishWithdraw = async () => {
    if (code[0] && code[1] && code[2] && code[3]) {
      const params = {
        token: code,
        issuerWithdrawId: withdrawDetails.issuerWithdrawId,
      };
      const response = await validateToken(params);
      if (response.status) {
        const response = await finishWithdrawing(withdrawDetails.issuerWithdrawId, { token: code });
        if (response) {
          setSucess(true);
          setShow(false);
        }
      }
    } else {
      const success = { ...successObj };
      success.msg = t('pages.withdrawList.codeFail').toString();
      setRetry(success);
    }
  };

  useEffect(() => {
    if (step === 1 && withdrawDetails) sendToken();
  }, [step]);

  useEffect(() => {
    const success = { ...successObj };
    success.msg = '';
    setRetry(success);
  }, [setCode, code]);

  useEffect(() => {
    const getBanks = async () => {
      const data = await getAllBanks();
      const mappedBank = Array.from(data)
        .map((item) => {
          if (item.code === parseInt(withdrawDetails.issuerBankData.bankCode)) {
            return {
              label: `${item.code} - ${item.fullName}`,
            };
          }
        })
        .filter((item) => item !== undefined);
      setBankInfo(mappedBank);
    };
    if (withdrawDetails) getBanks();
  }, [withdrawDetails]);

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
      <Modal.Body
        style={{ margin: 0, padding: '1.25rem 0 ' }}
        className="row d-flex flex-column justify-content-center align-items-center"
      >
        <div className="row" style={{ margin: 0, padding: '0 2.5rem' }}>
          <div
            className="col-12 d-flex justify-content-between align-items-center flex-row "
            style={{ marginBottom: '1.25rem', padding: 0 }}
          >
            <h4 className="col-5 modal-title-deposit">
              {!isDone ? (
                <Translator path={`pages.withdrawList.request.${step === 0 ? 'title' : 'requesting'}`} />
              ) : (
                <Translator path={'pages.withdrawList.request.titleRequested'} />
              )}
            </h4>
            <i className="material-icons moda-close-icon cursor-pointer" onClick={onHideModal}>
              close
            </i>
          </div>
        </div>
        {step === 0 ? (
          <>
            <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
            <div className="row withdraw-header" style={{ padding: '0 2.5rem', margin: '2.5rem 0 0 0' }}>
              <div className="row col-7 request-modal-header" style={{ margin: 0, padding: 0 }}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ margin: 0, padding: 0, width: 'fit-content' }}
                >
                  <img
                    src={withdrawDetails?.token?.image}
                    alt=""
                    style={{ width: '80px', height: '80px', borderRadius: '12px' }}
                  />
                </div>
                <div className="row col-8 d-flex justify-content-start align-items-center">
                  <p
                    className="label-withdraw"
                    style={{ whiteSpace: 'nowrap', margin: 0, padding: 0, width: 'fit-content' }}
                  >
                    <Translator path="pages.withdrawList.request.name" />:
                  </p>
                  <p className="col-5 info" style={{ whiteSpace: 'nowrap', margin: '0 0 0 0.5rem', padding: 0 }}>
                    {withdrawDetails?.token.name}
                  </p>
                  <p
                    className="label-withdraw"
                    style={{ whiteSpace: 'nowrap', margin: 0, padding: 0, width: 'fit-content' }}
                  >
                    <Translator path="pages.withdrawList.request.issuer" />:
                  </p>
                  <p className="col-6 info" style={{ whiteSpace: 'nowrap', margin: '0 0 0 0.5rem', padding: 0 }}>
                    {withdrawDetails?.issuer.name}
                  </p>
                </div>
              </div>
              <div className="row col-12 d-flex align-items-center request-modal-header-info">
                <p className="col-6 label-withdraw" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
                  {!isDone ? (
                    <Translator path="pages.withdrawList.request.amount" />
                  ) : (
                    <Translator path="pages.withdrawList.request.requestedAmount" />
                  )}
                </p>
                <p
                  className="col-6 info-value"
                  style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}
                >{`R$ ${new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 2,
                }).format(parseFloat(withdrawDetails?.value))}`}</p>
                <p className="col-6 label-withdraw" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
                  {!isDone ? (
                    <Translator path="pages.withdrawList.request.date" />
                  ) : (
                    <Translator path="pages.withdrawList.request.dateRequested" />
                  )}
                </p>

                <p className="col-6 info" style={{ whiteSpace: 'nowrap', margin: 0, padding: 0 }}>
                  {!isDone
                    ? new Date(Date.now()).toLocaleDateString('pt-BR')
                    : withdrawDetails && withdrawDetails.withdrawDate && new Date(withdrawDetails.withdrawDate)}
                </p>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
        <div className="row withdraw-body ">
          {step === 0 ? (
            <>

              <h3>
                A chave PIX usada será o CNPJ da empresa cadastrada
              </h3>
              {/* TODO: Remover */}
              {/* <h3>
                <Translator path="pages.withdrawList.request.info" />
              </h3>
              <div className="row col-12 d-flex align-items-center info-body">
                <p className="col-6 withdraw-label">
                  <Translator path="pages.withdrawList.request.bank" />
                </p>
                <p className="col-6 withdraw-info">{bankInfo ? bankInfo[0].label : ''}</p>
                <p className="col-6 withdraw-label">
                  <Translator path="pages.withdrawList.request.accountType" />
                </p>
                <p className="col-6 withdraw-info">{withdrawDetails?.issuerBankData.accountType}</p>
                <p className="col-6 withdraw-label">
                  <Translator path="pages.withdrawList.request.agency" />
                </p>
                <p className="col-6 withdraw-info">{`${withdrawDetails?.issuerBankData.agency} - ${withdrawDetails?.issuerBankData.agencyDigit}`}</p>
                <p className="col-6 withdraw-label">
                  <Translator path="pages.withdrawList.request.accountNumber" />
                </p>
                <p className="col-6 withdraw-info">
                  {`${withdrawDetails?.issuerBankData.accountNumber} - ${withdrawDetails?.issuerBankData.accountNumberDigit}`}
                </p>
                <p className="col-6 withdraw-label">
                  <Translator path="pages.withdrawList.request.keyType" />
                </p>
                <p className="col-6 withdraw-info">{withdrawDetails?.issuerBankData.pixTypeKey}</p>
                <p className="col-6 withdraw-label">
                  <Translator path="pages.withdrawList.request.pixKey" />
                </p>
                <p className="col-6 withdraw-info">{withdrawDetails?.issuerBankData.pixKey}</p>
              </div> */}
            </>
          ) : (
            <>
              <p className="requesting">
                <Translator path="pages.withdrawList.request.body" />
              </p>
              <p className="requesting-email">{withdrawDetails?.issuer.email}</p>
              <div className="col-12 d-flex justify-content-start" style={{ gap: '0 11px', margin: '2.188rem 0 0 0' }}>
                <CodeValidator props={{ setCode }} />
              </div>

              {retry.msg && (
                <div className="text-success fs-12" style={{ marginTop: '0.313rem' }}>
                  {retry.msg}
                </div>
              )}
              <div className="row col-12 d-flex justify-content-start align-items-center" style={{ margin: 0 }}>
                <div className="col-2 requesting" style={{ margin: 0, padding: 0 }}>
                  <Countdown />
                </div>
                <span
                  className="form-check d-inline-block step-one col-7 mt-2"
                  style={{ padding: 0, whiteSpace: 'nowrap' }}
                >
                  <label className="a-forgot-password" htmlFor="check1" style={{ alignSelf: 'start' }}>
                    <Translator path="pages.register.secondStep.send" />
                  </label>
                  <label className="a-forgot-password" style={{ alignSelf: 'end' }} onClick={() => resend()}>
                    <Translator path="pages.register.secondStep.code" />
                  </label>
                </span>
              </div>
            </>
          )}
        </div>
        <div className="col-12" style={{ borderBottom: '1px solid #EDEDED' }} />
        <div className="row" style={{ padding: '0 2.5rem', margin: 0 }}>
          <div
            className="col-12 d-flex justify-content-end"
            style={{ gap: '1.25rem', marginTop: '1.25rem', padding: 0 }}
          >
            {step === 0 ? (
              !isDone && !isReview ? (
                <>
                  <button className="btn btn-outlined col-4" onClick={() => setShow(false)}>
                    <Translator path="pages.withdrawList.request.cancel" />
                  </button>
                  <button className="btn btn-orange col-4" onClick={() => setStep(1)}>
                    <Translator path="pages.withdrawList.request.continue" />
                  </button>
                </>
              ) : (
                ''
              )
            ) : (
              <>
                <button className="btn btn-outlined col-4" onClick={() => setStep(0)}>
                  <Translator path="pages.withdrawList.request.goBack" />
                </button>
                <button
                  className={`btn btn-orange col-4 ${loadingButton ? 'd-flex justify-content-center disabled' : ''}`}
                  onClick={() => {
                    if (!loadingButton) finishWithdraw();
                  }}
                >
                  {loadingButton ? (
                    <div className="loading-spinner" style={{ width: '30px', height: '30px' }} />
                  ) : (
                    <Translator path="pages.withdrawList.request.finish" />
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestWithdraw;
